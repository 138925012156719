import { Box, Container, Stack, Paper } from '@mui/material'

export const MCenterXsStack = ({ children }) => (
  <Box m={{ xs: 5, md: 8, lg: 12 }}>
    <Container component="main" maxWidth="xs" fixed>
      <Stack spacing={{ xs: 3, lg: 5 }} alignItems="center">
        {children}
      </Stack>
    </Container>
  </Box>
)

export const Surface = ({ children = null }) => (
  <Paper component="main" sx={{ m: { xs: 1, md: 2 }, p: { xs: 1, md: 2 }, flex: 1 }}>
    {children}
  </Paper>
)
