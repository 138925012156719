import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { MCenterXsStack } from 'layouts/page'
import { signIn } from 'lib/auth'

import {
  UsernameField,
  PasswordField,
  SubmitButton,
  Header,
} from 'components/auth/form'

export const SignIn = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()

  const onSubmit = (e) => signIn(username, password, navigate, location) && e.preventDefault()

  return (
    <MCenterXsStack>
      <Header>Sign in</Header>
      <form noValidate onSubmit={onSubmit}>
        <UsernameField setUsername={setUsername} />
        <PasswordField setPassword={setPassword} />
        <SubmitButton>Sign In</SubmitButton>
      </form>
    </MCenterXsStack>
  )
}
/*<Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>*/
