import {
  Typography,
  Button as MuiButton,
  TextField,
  TextFieldProps,
} from '@mui/material'

export const Text = ({ children, variant = undefined }) => (
  <Typography variant={variant}>{children}</Typography>
)

export const Button = ({ children, fullWidth = true, ...rest }) => (
  <MuiButton
    variant="contained"
    fullWidth={fullWidth}
    {...rest} // href, (type, onClick, key)
  >
    {children}
  </MuiButton>
)

export const TextInput = ({ label, ...rest }: TextFieldProps) => (
  <TextField
    {...rest}
    required
    fullWidth
    id={label.toString().toLowerCase().replace(' ', '')}
    name={label.toString().toLowerCase().replace(' ', '')}
  />
)
