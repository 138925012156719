import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MCenterXsStack } from 'layouts/page'
import { signUp } from 'lib/auth'

import {
  UsernameField,
  EmailField,
  PasswordField,
  SubmitButton,
  Header,
} from 'components/auth/form'

export const SignUp = () => {
  const navigate = useNavigate()

  const [username, setUsername] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const onSubmit = (e) => signUp(username, password, email, navigate) && e.preventDefault()

  return (
    <MCenterXsStack>
      <Header>Sign up</Header>
      <form noValidate onSubmit={onSubmit}>
        <UsernameField setUsername={setUsername} />
        <EmailField setEmail={setEmail} />
        <PasswordField setPassword={setPassword} />
        <SubmitButton>Sign Up</SubmitButton>
      </form>
    </MCenterXsStack>
  )
}
