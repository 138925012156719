import { useState, useEffect, createContext } from 'react'
import {NavigateFunction, useNavigate} from 'react-router-dom'

import { CognitoUser } from '@aws-amplify/auth'
import { HubCallback } from '@aws-amplify/core'
import { Auth, Hub } from 'aws-amplify'

export const validate = async (username, code, navigate) => {
  await Auth.confirmSignUp(username, code, {})
  navigate('/account')
}

export const resetPassword = async (user, newPassword, code, email?) =>
  (await email)
    ? Auth.completeNewPassword(user, newPassword, { email })
    : Auth.forgotPasswordSubmit(user, code, newPassword)

export const signUp = async (username, password, email, navigate) => {
  await Auth.signUp({
    username,
    password,
    attributes: {
      email,
    },
  })
  navigate('/validate')
}

export const signOut = () => Auth.signOut()

export const signIn = async (username, password, navigate: NavigateFunction, location) => {
  try {
    console.log('signingin')
    console.log(location)
    const user = await Auth.signIn(username, password)
    console.log(user)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      console.log('need new password')
      navigate('/resetpassword', { state: { userRaw: JSON.stringify(user) } })
    } else {
      console.log('signed in, redirecting')
      navigate(location.state?.from?.pathname || '/', { replace: true })
    }
  } catch (err) {
    console.log(err)
    if (err.code === 'UserNotConfirmedException') {
      navigate('/validate')
    } else if (err.code === 'PasswordResetRequiredException') {
      Auth.forgotPassword(username)
      navigate('/resetpassword', { state: { user: username, method: 'forgot' } })
    } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
      console.log('incorrect password')
    } else if (err.code === 'UserNotFoundException') {
      navigate('/signup')
    } else {
      console.log(err)
    }
  }
}

const authEventHandler =
  (setUser): HubCallback =>
  ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
        setUser(data)
        break
      case 'signOut':
        setUser(null)
        break
      default:
        break
    }
  }
interface ILocationState {
  from?: {
    pathname?: string
  }
}

export function useSignedIn(location): boolean {
  const [signedIn, setSignedIn] = useState<boolean>(false)
  const locState: ILocationState | null = location.state
  const from = locState?.from?.pathname

  const navigate = useNavigate()

  useEffect(() => {
    const setCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        console.log(user)
        setSignedIn(!!user)
        if (user && from) {
          navigate(from, { replace: true })
        }
      } catch (e) {
        // console.log('not logged in')
        setSignedIn(false)
      }
    }
    setCurrentUser()
  }, [setSignedIn, from, navigate])

  useEffect(() => {
    const handleAuth: HubCallback = authEventHandler(u => setSignedIn(!!u))
    Hub.listen('auth', handleAuth)
    return () => Hub.remove('auth', handleAuth)
  }, [setSignedIn])

  return signedIn
}

export const AuthContext = createContext<CognitoUser | null>(null)

export interface IUserProp {
  user: CognitoUser
}
