import { lazy, Suspense } from 'react'
import { Route, BrowserRouter, Routes, useLocation } from 'react-router-dom'

import { AccountNavigate, SignInNavigate } from 'components/auth'
// import { useSignedIn } from 'lib/auth'
import { Account } from 'pages/account'
import { LinksList } from 'pages/LinksList'

import { ResetPassword } from 'pages/auth/ResetPassword'
import { SignIn } from 'pages/auth/SignIn'
import { SignUp } from 'pages/auth/SignUp'
import { Validate } from 'pages/auth/Validate'
import { defaultTheme, ThemeWrapper } from 'theme'

const Warhammer40k = lazy(() => import('pages/40k'))
const Blog = lazy(() => import('pages/blog'))
const Library = lazy(() => import('pages/library'))
const Astrology = lazy(() => import('pages/astrology'))
const Market = lazy(() => import('pages/market'))

export const AppRoutes = ({ signedIn }: { signedIn: boolean }) => {
  const location = useLocation()
  return (
    <Routes>
      <Route index element={<LinksList />} />

      <Route
        path="account"
        element={
          signedIn ? <Account /> : <SignInNavigate location={location} />
        }
      />

      {/* AUTH FLOW*/}
      <Route
        path="signup"
        element={signedIn ? <AccountNavigate /> : <SignUp />}
      />
      <Route
        path="validate"
        element={signedIn ? <AccountNavigate /> : <Validate />}
      />
      <Route
        path="signin"
        element={signedIn ? <AccountNavigate /> : <SignIn />}
      />
      <Route
        path="resetpassword"
        element={
          //signedIn ?
              <ResetPassword />
            //: <SignInNavigate location={location} />
        }
      />

      {/* DIFFERENT APPS/TOOLS */}
      <Route
        path="warhammer/*"
        element={
          <Suspense fallback={<>...</>}>
            <Warhammer40k />
          </Suspense>
        }
      />
      <Route
        path="blog"
        element={
          <Suspense fallback={<>...</>}>
            <Blog />
          </Suspense>
        }
      />
      <Route
        path="library"
        element={
          <Suspense fallback={<>...</>}>
            <Library />
          </Suspense>
        }
      />
      <Route
        path="astrology"
        element={
          <Suspense fallback={<>...</>}>
            <Astrology />
          </Suspense>
        }
      />
      <Route
        path="market"
        element={
          <Suspense fallback={<>...</>}>
            <Market />
          </Suspense>
        }
      />
    </Routes>
  )
}

const AuthWrappedApp = () => {
  // const location = useLocation()
  // const signedIn = useSignedIn(location)
  return <AppRoutes signedIn={false} />
}

const BrowserApp = () => (
  <ThemeWrapper theme={defaultTheme}>
    <BrowserRouter>
      <AuthWrappedApp />
    </BrowserRouter>
  </ThemeWrapper>
)

// const MemoryApp = () => (
//     <ThemeWrapper theme={defaultTheme}>
//         <MemoryRouter>
//             <AuthWrappedApp />
//         </MemoryRouter>
//     </ThemeWrapper>
// )

export default BrowserApp
