import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthContext } from 'lib/auth'

export const Private = ({ children }) =>
  useContext(AuthContext) ? children : null
export const AccountNavigate = () => <Navigate replace to="/account" />
export const SignInNavigate = ({ location }) => (
  <Navigate to="/signin" state={{ from: location }} replace />
)
