import { MCenterXsStack } from 'layouts/page'

import { Button } from 'components'

const staticHomePageLinks: { text: string; link: string }[] = [
  {
    text: 'Facebook /pfeifferfarmwa',
    link: 'https://www.facebook.com/pfeifferfarmwa/',
  },
  {
    text: 'Tiktok @pfeifferfarm',
    link: 'https://www.tiktok.com/@pfeifferfarm',
  },
  {
    text: 'Farmer\'s Market Products',
    link: '/market',
  },
]

export const LinksList = () => (
  <MCenterXsStack>
    {staticHomePageLinks.map(({ text, link }) => (
      <Button href={link} key={text}>
        {text}
      </Button>
    ))}
  </MCenterXsStack>
)
