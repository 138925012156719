import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { MCenterXsStack } from 'layouts/page'
import { resetPassword } from 'lib/auth'

import {
  EmailField,
  CodeField,
  NewPasswordField,
  SubmitButton,
  Header,
} from 'components/auth/form'

export const ResetPassword = () => {
  const { state } = useLocation()
  console.log(JSON.stringify(state))
  // const {
  //   state: { user, method },
  // } = useLocation()
  const { userRaw, method } = state
  const user = JSON.parse( userRaw )

  const [email, setEmail] = useState()
  const [code, setCode] = useState()
  const [newPassword, setNewPassword] = useState()

  const onSubmit = (e) => resetPassword(user, newPassword, code, email) && e.preventDefault()

  return (
    <MCenterXsStack>
      <Header>Reset Password</Header>
      <form noValidate onSubmit={onSubmit}>
        {
          method === 'forget'
            ? <CodeField setCode={setCode} />
            : <EmailField setEmail={setEmail} />
        }
        <NewPasswordField setNewPassword={setNewPassword} />
        <SubmitButton>Reset</SubmitButton>
      </form>
    </MCenterXsStack>
  )
}
/*<Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot code?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>*/
