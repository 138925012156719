import ReactDOM from 'react-dom'

import 'index.css'
import { Amplify } from 'aws-amplify'

import awsconfig from './aws-exports'

import App from 'App'
import * as serviceWorker from 'serviceWorker'
Amplify.configure(awsconfig)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
