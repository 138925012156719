import { TextInput, Text, Button } from 'components'

export const UsernameField = ({ setUsername }) => (
  <TextInput
    margin="normal"
    label="Username"
    autoComplete="username"
    autoFocus
    onChange={e => setUsername(e.target.value)}
  />
)

export const CodeField = ({ setCode }) => (
  <TextInput
    margin="normal"
    label="Code"
    type="code"
    onChange={e => setCode(e.target.value)}
  />
)

export const EmailField = ({ setEmail }) => (
  <TextInput
    label="Email Address"
    autoComplete="email"
    onChange={e => setEmail(e.target.value)}
  />
)

export const PasswordField = ({ setPassword }) => (
  <TextInput
    label="Password"
    type="password"
    autoComplete="current-password"
    onChange={e => setPassword(e.target.value)}
  />
)

export const NewPasswordField = ({ setNewPassword }) => (
  <TextInput
    label="Password"
    type="password"
    autoComplete="new-password"
    onChange={e => setNewPassword(e.target.value)}
  />
)

export const SubmitButton = ({ children }) => (
  <Button type="submit">{children}</Button>
)

export const Header = ({ children }) => <Text variant="h5">{children}</Text>
