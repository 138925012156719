import { MCenterXsStack } from 'layouts/page'
import { signOut } from 'lib/auth'

import { Button } from 'components'

// index should be Routes importing main component from other page as root

export const Account = () => (
  <MCenterXsStack>
    <Button onClick={signOut}>Sign Out</Button>
  </MCenterXsStack>
)
