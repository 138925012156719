import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MCenterXsStack } from 'layouts/page'
import { validate } from 'lib/auth'

import {
  UsernameField,
  CodeField,
  SubmitButton,
  Header,
} from 'components/auth/form'

export const Validate = () => {
  const navigate = useNavigate()

  const [username, setUsername] = useState()
  const [code, setCode] = useState()

  const onSubmit = (e) => validate(username, code, navigate) && e.preventDefault()

  return (
    <MCenterXsStack>
      <Header>Validation</Header>
      <form noValidate onSubmit={onSubmit}>
        <UsernameField setUsername={setUsername} />
        <CodeField setCode={setCode} />
        <SubmitButton>Validate</SubmitButton>
      </form>
    </MCenterXsStack>
  )
}
/*<Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot code?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>*/
